/*
* Admin Site Product Edit Page
*/
import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Row, FormGroup, Label, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ImageUploader from 'react-images-upload';
import * as Actions from 'store/actions'
import history from '@history'
import Domain from 'config.js'
import 'font-awesome/css/font-awesome.min.css';

function ProductEditDetail(props) {
    const dispatch = useDispatch()
    const user = useSelector(({auth}) => {
        return auth.login.success ? auth.user : false
    })
    const Cat = useSelector(({vendor}) => vendor.vendor.Categories)
    const [pictures, setPictures] = useState([])
    const [photoIndex, setPhotoIndex] = useState(0)
    const [isOpen, setIsOpen] = useState(false)
    const [modal1, setModal1] = useState(false)
    const [data, setData] = useState(null);
    const [newImage, setNewImage] = useState(props.product.pictures[0])
    const [formEdit, setFormEdit] = useState({ name: props.product.name, category: props.product.category.id ,salePrice: props.product.salePrice, stock: props.product.stock, description: props.product.description})
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };
    const productslider = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1
      };
      if(user === false ){
          history.push({
              pathname: '/'
          })
      }
      const onDeleteInvoicePopup = (id) => {
        setData(id)
        toggle1();
    }
      const toggle1 = () => {
        setModal1(!modal1)
      }
      const onChangeForm = (e) => {
        setFormEdit({
            ...formEdit,
            [e.target.name] : e.target.value
        })
      }
      const onDeleteImg =(e) => {
          e.preventDefault()
          dispatch(Actions.deleteProImage(data))
      }
      const formSubmit = (e) => {
        e.preventDefault()
        if(pictures.length !== 0){
            const fd = new FormData();
                fd.append('name', formEdit.name);
                fd.append('price', formEdit.price);
                fd.append('salePrice', formEdit.salePrice);
                fd.append('maxQuantity', formEdit.stock);
                fd.append('details', formEdit.description);
                fd.append('material_id', formEdit.category);
                fd.append('id', props.product.id)
                for(let i= 0; i< pictures.length; i++){
                    fd.append('image', pictures[i][0], `proImages${i}`)
                }
            console.log(fd)
            dispatch(Actions.updateProductVendor(fd))
        } else {
            console.log('no formdata')
            const edit = {
                id: props.product.id,
                name : formEdit.name,
                price : formEdit.price,
                salePrice : formEdit.salePrice,
                maxQuantity: formEdit.stock,
                details : formEdit.description,
                material_id : formEdit.category
            }
            dispatch(Actions.updateProductVendor(edit))
        }
    }

    const changePreviewImage = (image) => {
        setNewImage(image)
    }
    const ImageChange = (e) => {
        setPictures([...pictures, e])
    }
    const {product} = props;
    const images=[];
    {product.pictures.map((pic)=>
        images.push(`${Domain}${pic.image}`)
    )}
        return (
            <section>
                <div className="product-content-top single-product single-product-edit">
                    <Row>
                        <div className="product-top-left col-xl-5 col-md-6">
                            <div className="product-top-left-inner">
                                <div className="ciyashop-product-images">
                                <div className="ciyashop-product-images-wrapper ciyashop-gallery-style-default ciyashop-gallery-thumb_position-bottom ciyashop-gallery-thumb_vh-horizontal">
                                    <div className="ciyashop-product-gallery ciyashop-product-gallery--with-images slick-carousel">
                                    <Slider {...settings} className="ciyashop-product-gallery__wrapper popup-gallery">
                                        <div className="ciyashop-product-gallery__image">
                                                <img src={`${Domain}${newImage.image}`} className="img-fluid" />
                                                <div className='product-delete' style={{width: '50px', height: '50px', lineHeight: '57px', cursor: 'pointer'}}>
                                                <i onClick={() =>onDeleteInvoicePopup(newImage.id)} className="fa fa-trash-o fa-2x"></i></div>
                                        </div>
                                    </Slider>
                                    <div className="ciyashop-product-gallery_buttons_wrapper">
                                        <div className="ciyashop-product-gallery_button ciyashop-product-gallery_button-zoom popup-gallery" onClick={() => setIsOpen(true)} >
                                        <Link to="#" className="ciyashop-product-gallery_button-link-zoom">
                                            <i className="fa fa-arrows-alt" />
                                        </Link>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="ciyashop-product-thumbnails">
                                        <Slider {...productslider} className="ciyashop-product-thumbnails__wrapper">
                                            {product.pictures.map((pictureimage,index) =>
                                            <div className="ciyashop-product-thumbnail__image" key={index}>
                                                <Link  onMouseOver={() => changePreviewImage(pictureimage)} to='#'>
                                                    <img src={`${Domain}${pictureimage.image}`}  className="img-fluid" />
                                                </Link>
                                            </div>
                                            )}
                                        </Slider>
                                    </div>
                                    <div className="clearfix" />
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="product-top-right col-xl-7 col-md-6">
                        <div className="product-top-right-inner">
                            <form className="summary entry-summary" onSubmit={formSubmit}>
                                    <FormGroup className="edit-icon">
                                        <Input type="text" className="form-control product_title" name='name' placeholder="Product Name" defaultValue={product.name} onChange={e => onChangeForm(e)} />
                                    </FormGroup>
                                    <FormGroup className="edit-icon">
                                        <Input type="text" className="form-control price" name='price' placeholder="Product Price" defaultValue={`$${product.price}`} onChange={e => onChangeForm(e)} />
                                    </FormGroup>
                                    <FormGroup className="edit-icon">
                                        <Input type="text" className="form-control price" name='salePrice' placeholder="Product Price" defaultValue={`$${product.salePrice}`} onChange={e => onChangeForm(e)} />
                                    </FormGroup>
                                    <FormGroup className="edit-icon">
                                        <Input  type="textarea" className="form-control" rows="3"name='description' placeholder="Product Description" defaultValue={product.description} onChange={e => onChangeForm(e)} />
                                    </FormGroup>
                                    <Label className="title">Category</Label>
                                    <select name="category" id="category"  className="form-control" onChange={e => onChangeForm(e)} >
                                        <option selected="selected" value = {product.category.id}>{product.category.name}</option>
                                        {Cat && Cat !==null ?
                                        Cat.map(cat => (
                                                <option key = {cat.id} value = {cat.id}>{cat.name}</option>
                                        )) : <option value ='0'>wait</option>}
                                    </select>

                                    <Label className="title">Product Stock</Label>
                                    <input type="text" class="form-control" name='stock' placeholder="Product Stock" defaultValue={product.stock} onChange={e => onChangeForm(e)}></input>

                                    <button type='submit' href="#" class="btn btn-primary mb-2 mr-2"> Update </button>
                                    <Link to="/vendor/Product" class="btn btn-danger mb-2"> Cancel </Link>
                                    <div style={{width : '180px', height: '100%', background: '#e6e6e6'}} className="ciyashop-product-thumbnails__wrapper">
                                    <div className="ciyashop-product-thumbnail__image">
                                        <a href ='#'>
                                        <div src= '#'style={{width: '200px', height: '200px'}}></div>
                                        </a>
                                        <div className="d-flex justify-content-center image-content align-items-center">
                                            <ImageUploader
                                                buttonText=""
                                                onChange={ImageChange}
                                                withPreview
                                                withIcon={false}
                                                maxFileSize={5242880}
                                                imgExtension={['.jpg', 'jpeg','.gif', '.png', '.gif']}
                                            />
                                        </div>
                                    </div>
                                    </div>
                            </form>
                        </div>
                        </div>
                    </Row>
                    </div>
                    <div>
                        {isOpen && (
                            <Lightbox
                                mainSrc={images[photoIndex]}
                                nextSrc={images[(photoIndex + 1) % images.length]}
                                prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                                onCloseRequest={() => setIsOpen(false)}
                                onMovePrevRequest={() =>
                                setPhotoIndex((photoIndex + images.length - 1) % images.length)}
                                onMoveNextRequest={() =>
                                setPhotoIndex((photoIndex + 1) % images.length)
                                }/>
                        )}
                    </div>
                    <Modal isOpen={modal1} toggle={toggle1} className="modal-delete modal-lg modal-dialog-centered">
                    <ModalHeader toggle={toggle1}></ModalHeader>
                    <ModalBody>
                      Are You Sure You Want To Delete This Image Permanently?
                    </ModalBody>
                    <ModalFooter className="justify-content-center pt-4">
                      <Link className="action-button" to="#" onClick={(e)=>onDeleteImg(e)}>Yes</Link>
                      <Link className="action-button no" to="#" onClick={toggle1}>No</Link>
                    </ModalFooter>
                  </Modal>

        </section>

        )
}
export default ProductEditDetail;

