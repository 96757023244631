import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Nav, NavItem, NavLink, TabContent, TabPane, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import * as Actions from 'auth/store/actions'
import history from '@history';
import './loginStyle.css'
import 'animate.css'

function VendorLogin() {
    const dispatch = useDispatch()
    const [activeTab, setActiveTab] = useState("1")
    const Auth = useSelector(({auth}) => {
        return auth.login.success ? auth.user : false
    })
    useEffect(() => {
        if(Auth && Auth.role === 'Vendor'){
            history.push({
                pathname: '/vendor/dashboard'
            })
        }
    },[Auth])
    const logintoggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }}
    const loginSubmit = (e) => {
        e.preventDefault()
        const loginData = {
            email : e.target.email.value,
            password: e.target.password.value
        }
        console.log(loginData)
        dispatch(Actions.submitLoginVendor(loginData))
    }
    const signupSubmit = e => {
        e.preventDefault()
        const signupData = {
            email : e.target.email.value,
            password: e.target.password.value,
            password2: e.target.password2.value
        }
        console.log(signupData);
    }
    return (
    <div>
        <div className="main-container">
            <div className="bg-div">
                <div className="bg-content animated bounceInLeft">
                    <h2 className='title-h2'>Multi Vendor Store</h2>
                    <h6 className='lorem-h6'>Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Provident voluptates facilis eveniet hic harum rem culpa,
                        sunt maxime suscipit ea impedit reiciendis rerum obcaecati 
                        eum laudantium itaque, quibusdam accusantium earum.</h6>
                </div>
            </div>
            <div className="login-panel">
            <div className="login-content">
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '1' })}
                            onClick={() => { logintoggle('1'); }}>Sign In</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '2' })}
                            onClick={() => { logintoggle('2'); }}>Register</NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <div className="tabs-div">
                    <h2 className='text-center my-4'>Vendor Login</h2>
                    <form onSubmit={e => loginSubmit(e)}>
                        <div className='input-groups'>
                            <label>Email</label>
                            <input type="text" name="email" placeholder='Email' className='theinputs'/>
                        </div>
                        <div className='input-groups'>
                            <label>Password</label>
                            <input type="password" name="password" placeholder='Password' className='theinputs'/>
                        </div>
                        <div className="input-groups">
                            <Button color= 'primary' className='btn-login'>Login</Button>
                        </div>
                    </form>
                    <div className='lower-div'>
                        <span className= 'text-dark'>Don't have and account?</span>
                        <Link to='#'onClick={() => { logintoggle('2'); }}>Create an account</Link>
                        <Link to='/'>Back to Homepage</Link>
                    </div>
                    </div>
                </TabPane>
                <TabPane tabId="2">
                    <div className="tabs-div">
                    <h2 className='text-center my-4'>Vendor Signup</h2>
                    <form onSubmit={e => signupSubmit(e)}>
                        <div className='input-groups'>
                            <label>Email</label>
                            <input type="text" name="email" placeholder='Email' className='theinputs'/>
                        </div>
                        <div className='input-groups'>
                            <label>Password</label>
                            <input type="password" name="password" placeholder='Password' className='theinputs'/>
                        </div>
                        <div className='input-groups'>
                            <label>Password</label>
                            <input type="password" name="password2" placeholder='Retype Password' className='theinputs'/>
                        </div>
                        <div className="input-groups">
                            <Button color= 'primary' className='btn-login'>Signup</Button>
                        </div>
                    </form>
                    <div className='lower-div'>
                        <Link to='/'>Back to Homepage</Link>
                    </div>
                    </div>
                </TabPane>
                </TabContent>
            </div>
            </div>
        </div>
    </div>
    )
}
export default VendorLogin