import axios from 'axios';
import Domain from 'config.js'
export const GET_PROFILE = '[PROFILE PAGE] GET PROFILE';
export const GET_ORDERS = '[PROFILE PAGE] GET ORDERS';
export const CANCEL_ORDER = '[PROFILE PAGE] CANCEL ORDER';

export function getProfile(id) {
    const request = axios.get(`${Domain}/v1/buyer/profile`, {
        params: {
            id
        }
    });
    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: GET_PROFILE,
                payload: response.data.data
            })
        }
        )
    )
}
export function getOrders(id) {
    console.log(id)
    const request = axios.get(`${Domain}/v1/buyer/orders`, {
        params: {
            id
        }
    });

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: GET_ORDERS,
                payload: response.data.data
            })
        }
        )
    )
}
export function cancelOrder(id) {
    return (dispatch, getState) => {
        const user  = getState().auth.user;
        const request = axios.get(`${Domain}/v1/buyer/cancelOrder`, {
        params: {
            id
        }
    });

    return request.then((response) => {
            return dispatch({
                type: CANCEL_ORDER,
            })
        }
        ).then(() => dispatch(getOrders(user.id)))
        .catch((err) => console.log(err))
    }
}