/**
 * Shop Page Filter Banner
 */
import React , {Component} from 'react';

function ShopBanner() {
       return (
        <div className="widget widget_media_image">
              <img  src={require(`../../assets/images/shop/sale-banner2.jpg`)}  className="img-fluid" alt="sale" />
        </div>

       )
}
export default ShopBanner;