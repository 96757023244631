import * as Actions from '../../actions';
import * as moment from 'moment';
import Domain from 'config.js'

const initialState = {
    Categories : null,
};

const matreducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_MATERIALS:
            {
                let resFormattedMapped = []
                if (action.payload) {
                    let res = action.payload
                    resFormattedMapped = res.map(mat => (
                        {
                            id: mat.id,
                            name: mat.name,
                            avatar: `${Domain}${mat.image}`,
                            subcategories: mat.subcategories,
                            created_at: moment(mat.datetime).format('MMM DD YYYY h:mm A')
                        }
                    ))
                }
                return {
                    ...state,
                    Categories: resFormattedMapped,
                };
            }
        default:
        {
            return state;
        }
    }
};

export default matreducer;
