import * as Actions from '../../actions/orders.actions';

const initialState = {
    orders: null,
};

const OrdersReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_ORDERS:
            {
                let orders = action.payload
                Object.keys(orders).forEach(function (key) { //replace null values with empty strings
                    if (orders[key] === null) {
                        orders[key] = '';
                    }
                })

                return {
                    ...state,
                    orders
                }
            }
        default:
            {
                return state;
            }
    }
};

export default OrdersReducer;
