/**
 *  Profile Edit
 */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col,Container,Button } from 'reactstrap';
import Adminsitebar from './Adminsitebar';
import Loader from 'react-loader-spinner';
import AdminHeader from './Adminheader';
import * as Actions from 'store/actions'

function Profileedit(props) {
  const dispatch = useDispatch()
  const [fieldvalue, setFieldvalue] = useState(null)
  const [errors, setErrors] = useState({})
  const userData = useSelector(({auth}) => {
      return auth.login.success ? auth.user : false
  })
  useEffect(() => {
      if(userData){
          window.scrollTo(0, 0)
          setFieldvalue(userData.data)
      }
  }, [userData])
  if(fieldvalue === null){
    return (
        <div>
            <div id="preloader">
                <Loader type="Puff" color="#04d39f" height={100} width={100} />
            </div>
        </div>
    )
}
  const handleValidation = () => {
        let errors = {};
        let formIsValid = true;

        //First Name
        if (!fieldvalue["firstName"]) {
            formIsValid = false;
            errors["firstName"] = "Please Enter First Name";
        }

        if (typeof fieldvalue["firstName"] !== "undefined") {
            if (!fieldvalue["firstName"].match(/^[a-zA-Z]+$/)) {
              formIsValid = false;
              errors["firstName"] = "Please Enter Only Letter";
            }
        }

        //Last Name
        if (!fieldvalue["lastName"]) {
            formIsValid = false;
            errors["lastName"] = "Please Enter Last Name";
        }

        if (typeof fieldvalue["lastName"] !== "undefined") {
            if (!fieldvalue["lastName"].match(/^[a-zA-Z]+$/)) {
              formIsValid = false;
              errors["lastName"] = "Please Enter Only Letter";
            }
        }
      //Last Name
      if (!fieldvalue["phone"]) {
          formIsValid = false;
          errors["phone"] = "Please Enter Phone no";
      }

      //Address
      if (!fieldvalue["address"]) {
        formIsValid = false;
        errors["address"] = "Please Enter Address";
      }
      //Address
      if (!fieldvalue["city"]) {
        formIsValid = false;
        errors["city"] = "Please Enter city";
      }

      setErrors(errors);
      return formIsValid;
  }

  const onProfileFormSubmit = (e) => {
    e.preventDefault();
    const edit = {
      id: userData.id,
      firstName : fieldvalue.firstName,
      lastName : fieldvalue.lastName,
      phone : fieldvalue.phone,
      address : fieldvalue.address,
      city : fieldvalue.city
    }
    console.log(edit)
    if(handleValidation()){
       dispatch(Actions.updateVendorProfile(edit))
    }
  }


  const handleChange = (e) => {
    setFieldvalue({
      ...fieldvalue,
      [e.target.name] : e.target.value
    })
  }
    return (
      <>
      <AdminHeader />
      <div className="section-ptb">
            <Container>
             <Row>
             <Adminsitebar />
              <Col lg={9} className="mt-4 mt-lg-0">
                <Row>
                <Col lg={12}>
                  {fieldvalue === null ?
                  <div className= 'text-center'>
                    <Loader type="Puff" color="#04d39f" height={100} width={100}/>
                  </div>:
                  <div className="woocommerce-Address woocommerce-Address-edit">
                  <div className="woocommerce-Address-title">
                    <h5 className="mb-0">Profile Information Edit </h5>
                  </div>
                  <div className="woocommerce-Address-info mt-4">
                  <form onSubmit={onProfileFormSubmit}>
                    <div className="form-group">
                      <label>First name</label>
                      <input type="text" className="form-control" value={fieldvalue.firstName} onChange={e => handleChange(e)} placeholder="First name" name ='firstName'/>
                      <span className="error">{errors["firstName"]}</span>
                    </div>
                    <div className="form-group">
                      <label>Last name</label>
                      <input type="text" className="form-control"  value={fieldvalue.lastName} onChange={e => handleChange(e)}  placeholder="Last name" name='lastName'></input>
                      <span className="error">{errors["lastName"]}</span>
                    </div>
                      <div className="form-group">
                        <label>Phone</label>
                        <input type="text" className="form-control" placeholder="Phone no" value={fieldvalue.phone} onChange={e => handleChange(e)} name= 'phone'></input>
                        <span className="error">{errors["phone"]}</span>
                      </div>
                      <div className="form-group">
                        <label>Email</label>
                        <input type="Email" className="form-control" placeholder="Email" value={fieldvalue.email} onChange={e => handleChange(e)} name ='email'></input>
                        <span className="error">{errors["email"]}</span>
                      </div>
                      <div className="form-group">
                        <label>Address</label>
                        <textarea className="form-control" rows="3" placeholder="Address" value={fieldvalue.address} onChange={e => handleChange(e)} name ='address'></textarea>
                        <span className="error">{errors["address"]}</span>
                      </div>
                      <div className="form-group">
                        <label>City</label>
                        <textarea className="form-control" placeholder="Address" value={fieldvalue.city} onChange={e => handleChange(e)} name ='city'></textarea>
                        <span className="error">{errors["city"]}</span>
                      </div>
                      <Button type="submit"  className="btn btn-primary">Save</Button>
                  </form>
                  </div>
                </div>}
                </Col>
                </Row>
              </Col>
            </Row>
            </Container>
            </div>
            </>
        )
}
export default Profileedit;
