import React, { Fragment, useState, useEffect} from 'react';
import {
    Modal, ModalHeader, ModalBody, DropdownMenu, DropdownItem, Navbar,
    Container, Nav, NavItem, NavLink, UncontrolledDropdown, Collapse, TabContent, TabPane, Button
} from 'reactstrap';
import { Link } from 'react-router-dom';
import classnames from 'classnames';



function LoginPage (){
    const [activeTab, setActiveTab] = useState("1")

    const logintoggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }}

    const loginSubmit = (e) => {
        e.preventDefault()
        const loginData = {
            email : e.target.email.value,
            password: e.target.password.value
        }
        console.log(loginData)
    }
    return (
            <Container>
                <div className="modal-login modal-dialog-centered">
                    <div>
                        <h4 className="mb-0">Sign in Or Register</h4>
                    </div>
                        <Container>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                    className={classnames({ active: activeTab === '1' })}
                                    onClick={() => { logintoggle('1'); }}>Sign In
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                    className={classnames({ active: activeTab === '2' })}
                                    onClick={() => { logintoggle('2'); }}>Register
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <form onSubmit= {e => loginSubmit(e)}>
                                        <div className="form-group">
                                            <label>Email address</label>
                                            <input type="text" className="form-control" name='email' placeholder="Enter email"></input>
                                        </div>
                                        <div className="form-group">
                                            <label>Password </label>
                                            <input type="text" className="form-control" name='password' placeholder="Password"></input>
                                        </div>

                                        <div className="form-group">
                                            <Button className="btn btn-primary mt-1" type="submit">Log in</Button>
                                        </div>
                                            <p className="mb-0">Don't have account? <Link to="#" className={classnames({ active: activeTab === '2' })}
                                                            onClick={() => { logintoggle('2'); }} > Register </Link>here</p>
                                    </form>
                                </TabPane>
                                <TabPane tabId="2">
                                    <form>
                                        <div className="form-group">
                                            <label>Name</label>
                                            <input type="texttext" className="form-control" placeholder="Name"></input>
                                        </div>
                                        <div className="form-group">
                                            <label>Email address</label>
                                            <input type="text" className="form-control" placeholder="Enter email"></input>
                                        </div>
                                        <div className="form-group">
                                            <label>Password </label>
                                            <input type="text" className="form-control" placeholder="Password"></input>
                                        </div>
                                        <div className="form-group">
                                            <label>Confirm Password </label>
                                            <input type="text" className="form-control" placeholder="Confirm Password"></input>
                                        </div>
                                        <div className="form-group">
                                            <Link to="#" className="btn btn-primary" >Register</Link>

                                        </div>
                                        <p className="mb-0">Already have account? <Link to="#" className={classnames({ active: activeTab === '1' })}
                                            onClick={() => { logintoggle('1'); }} > SignIn </Link> here </p>
                                    </form>
                                </TabPane>
                            </TabContent>
                        </Container>
                </div>
                <div className="col-12">
                    <div className="mobile-menu" id="mobileMenu" />
                </div>
            </Container>
    )
}
export default LoginPage