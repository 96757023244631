import * as Actions from '../../actions';

const initialState = {
    cart : [],
};

const dialog = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.ADD_CART:
            {
                 return {
                    ...state,
                    cart: [...state.cart, action.payload],
                    errorMessage: ''
                };
            }
        case Actions.REMOVE_CART:
            {
                 return {
                    ...state,
                    cart : state.cart.filter(item => item.id !== action.payload),
                    errorMessage: ''
                };
            }
        default:
        {
            return state;
        }
    }
};

export default dialog;