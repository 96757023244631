/**
 *  Admin Profile Page
 */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col,Container,FormGroup,Form,Input,Button } from 'reactstrap';
import Adminsitebar from './Adminsitebar';
import AdminProfileDetail from '../../widgets/AdminProfileDetail';
import Loader from 'react-loader-spinner';
import AdminHeader from './Adminheader';

function Profile() {
  const [Profile, setProfile] = useState(null)
  const userData = useSelector(({auth}) => {
      return auth.login.success ? auth.user : false
  })
  useEffect(() => {
      if(userData){
          window.scrollTo(0, 0)
          setProfile(userData.data)
      }
  }, [userData])
  if(Profile === null){
    return (
        <div id="preloader">
            <Loader type="Puff" color="#04d39f" height={100} width={100} />
        </div>
    )
}
  return (
    <>
    <AdminHeader />
      <div className="section-ptb">
        <Container>
          <AdminProfileDetail Profile= {Profile}/>
          <Row>
            <Adminsitebar />
          <Col lg={9} className="mt-4 mt-lg-0">
            <Row>
            <Col lg={12}>
              <div className="woocommerce-Address">
                <div className="woocommerce-Address-title">
                  <h5 class="mb-0">Profile Information</h5>
                  <Link class="edit" to="/vendor/profileedit">Edit</Link>
                </div>
                <div className="woocommerce-Address-info mt-4">
                  <ul class="list-unstyled mb-0">
                    <li><span>First name:</span><strong>{Profile.firstName}</strong></li>
                    <li><span>Last name:</span><strong>{Profile.lastName}</strong></li>
                    <li><span>Phone no:</span><strong>{Profile.phone}</strong></li>
                    <li><span>Email:</span><strong>{Profile.email}</strong></li>
                    <li><span>Address:</span><strong>{Profile.address}</strong></li>
                  </ul>
                </div>
              </div>
            </Col>
            </Row>
          </Col>
        </Row>
        </Container>
      </div>
    </>
        )
}
export default Profile;
