import axios from 'axios';
import Domain from 'config.js'
export const GET_MATERIALS = '[LANDING PAGE] GET MATERIALS';

export function getMaterials() {
    const request = axios.get(`${Domain}/v1/buyer/categories`);

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: GET_MATERIALS,
                payload: response.data.materials
            })
        }
        )
    )
}
