import { toast } from 'react-toastify';
import axios from 'axios';
import Domain from 'config.js'
export const UPDATE_VENDOR_PROFILE = '[VENDOR APP] UPDATE_VENDOR_PROFILE';

export function updateVendorProfile(data) {
    const request = axios.put(`${Domain}/v1/vendor/updateVendorProfile`, data);
    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: UPDATE_VENDOR_PROFILE
            })
        }
        ).then(() => window.location.reload())
        .then(() => {
            toast.success('Successfully Updated')
        })
        .catch(error => {
            console.log(error.response)
            if (error.response) {
                toast.warn('Cannot Updat Profile')
            }
            else {
                console.log(error)
            }
        })
    )
}