/**
 *  Admin Invoive Page
 */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col,Container,Modal, ModalBody, ModalHeader } from 'reactstrap';
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import { Link } from 'react-router-dom';
import AdminHeader from './Adminheader.js';
import * as Actions from 'store/actions'
import Loader from 'react-loader-spinner';
import Domain from 'config.js'

function Invoices(props) {
    const dispatch = useDispatch()
    const [modal, setModal] = useState(false)
    const [searchProduct, setSearchProduct] = useState('')
    const [invoiceview, setInvoiceview] = useState('')
    const [invoices, setInvoices] = useState(null)
    const auth = useSelector(({auth}) => {
      return auth.login.success ? auth.user : false
  });
  const invData = useSelector(({vendor}) => vendor.vendor.vendorOrders)
  useEffect(() => {
    window.scrollTo(0, 0)
    if(auth){
        dispatch(Actions.getVendorOrders(auth.id))
    }
    },[])
  useEffect(() => {
    if(invData){
      setInvoices(invData)
    }
    },[invData])
    const toggle = () => {
      setModal(!modal)
    }
    const onSearchProduct = (searchText) => {
      console.log(searchText)
    }

    const onViewInvoicePopup = (data) => {
      setInvoiceview(data)
      toggle();
    }
      const viewInvoice = invoiceview;
      const columns = [
        {
           maxWidth: 75,
           Header: 'No.',
           accessor: 'id'
        },
        {
           sortable: false,
           Header: 'Transaction Id',
           accessor: 'invoiceid'
        },
        {
           minWidth: 160,
           Header: 'Buyer',
           accessor: 'buyer',
        },
        {
           Header: 'Date',
           accessor: 'date',
        },
        {
          Header: 'Status',
          accessor: 'status',
        },
        {
          Header: 'Payment',
          accessor: 'payment',
        },
         {
           Header: 'Action',
           accessor: 'action',
           Cell: props => {
              return (
                 <div>
                    <Link className="view-button" onClick={() => onViewInvoicePopup(props.original)} > View <i className="fa fa-eye pl-2"></i></Link>
                 </div>
              )
           },
        }
     ]
     var subtotal = null
     var totalshipping = null
     if(viewInvoice && viewInvoice !== null) {
      viewInvoice.products.map(pro => {
        subtotal += pro.salePrice * pro.quantity
        totalshipping += pro.shipping
      })
     }
     if(invoices === null){
      return (
          <div id="preloader">
              <Loader type="Puff" color="#04d39f" height={100} width={100} />
          </div>
      )
  }

    return (
      <div>
        <AdminHeader />
      <div className="section-ptb">
      <Container>
        <Row>
        <Col lg={12}>
           <div className="mb-0">
              <h4>Invoice List</h4>
              </div>
              <div className="mb-4">
                <form>
                <div class="form-group">
                  <input type="text" class="form-control"  placeholder="Search Invoice" value={searchProduct} onChange={(e) => onSearchProduct(e.target.value)}></input>
                </div>
                </form>
              </div>
                <ReactTable className="invoices-table"
                    data={invoices}
                    columns={columns}
                    minRows={1}
                    defaultPageSize={5}
                />
                  {/* modal-view */}
                  <Modal isOpen={modal} toggle={toggle} className="modal-view modal-lg modal-dialog-centered">
                    <ModalHeader toggle={toggle}></ModalHeader>
                    {viewInvoice !== null ?
                    <ModalBody>
                      <div className="success-screen">
                      <div className="thank-you text-center">
                        <i className="fa fa-check-circle-o"></i>
                        <h1 className="text-white">Thank You</h1>
                        <span>Success! We received your payment. Your order will be processed soon.</span>
                        <strong className="text-white">Transaction ID:{viewInvoice.invoiceid}</strong>
                      </div>
                      <div className="delivery p-4 p-md-5 bg-light text-center">
                        <span className="h5">Expected Date Of Delivery</span>
                        <h2 className="mb-0 mt-2">{viewInvoice.date}</h2>
                      </div>
                      <div className="pt-4 px-4 pt-md-5 px-md-5 pb-3">
                      <Row>
                        <Col lg={6}>
                        <h6>Ship To</h6>
                          <ul className="list-unstyled mb-0">
                            <li>{viewInvoice.buyer}</li>
                            <li>{viewInvoice.buyerPhone}</li>
                            <li>{viewInvoice.buyerEmail}</li>
                            <li>{viewInvoice.deliveryAddress}</li>
                          </ul>
                        </Col>
                        <Col lg={6} className="text-lg-right mt-4 mt-lg-0">
                          <h6>Summary</h6>
                          <ul className="list-unstyled mb-0">
                            <li><span>Order ID:</span> <strong>{viewInvoice.invoiceid}</strong></li>
                            <li><span>Order Date:</span> <strong>{viewInvoice.date}</strong></li>
                            <li><span>Order Total:</span> <strong>${subtotal + totalshipping}.00</strong></li>
                          </ul>
                        </Col>
                      </Row>
                      </div>
                      <div className="ordered-detail">
                        <h5 className="mb-4">Your Ordered Details</h5>
                        <div className="table-responsive">
                        <table class="table mb-0">
                          <thead>
                            <tr>
                            <th scope="col">Picture</th>
                            <th scope="col">Product Name</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Price</th>
                            <th scope="col">Shipping</th>
                            </tr>
                          </thead>
                          <tbody>
                          {viewInvoice && viewInvoice !== null ?
                          viewInvoice.products.map(inv => (
                            <tr className="ordered-item" key= {inv.productOrderId}>
                            <td  className="ordered-name">
                              <span><img src={`${Domain}${inv.pictures}`}  className="img-fluid" width='100px'/></span>
                            </td>
                            <td  className="ordered-name">
                              <span>{inv.productName}</span>
                            </td>
                            <td className="ordered-quantity">
                              <span>{inv.quantity}</span>
                            </td>
                            <td className="ordered-price">
                            <span>${inv.salePrice}.00</span>
                            </td>
                            <td className="ordered-price">
                            <span>${inv.shipping}.00</span>
                            </td>
                          </tr> 
                          )) : <tr>null</tr>
                        }
                            
                          </tbody>
                        </table>
                        </div>
                        <div className="table-responsive">
                        <table class="table total-table table-borderless mt-4 mb-0">
                          <tbody>
                            <tr>
                              <td>Subtotal</td>
                              <td className="text-right">${subtotal}.00</td>
                            </tr>
                            <tr>
                            <td>Shipping</td>
                            <td className="text-right">${totalshipping}</td>
                            </tr>
                            <tr className="border-top">
                            <td><strong className="h5">Total</strong></td>
                              <td className="text-right h5"><strong>${subtotal + totalshipping}.00</strong></td>
                            </tr>
                          </tbody>
                        </table>
                        </div>
                      </div>
                    </div>
                    </ModalBody>
                    :
                      null
                    }
                  </Modal>
                  </Col>
                </Row>
                </Container>
                </div>
              </div>
          )
}
export default Invoices;
