/**
 *  Admin Site Product Listing Page
 */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col,Container } from 'reactstrap';
import AdminproductList from '../../widgets/AdminProduct';
import Pagination from '../../services/Pagination';
import * as actions from 'store/actions'
import Loader from 'react-loader-spinner';
import AdminHeader from './Adminheader';
function AdminProduct() {
    const dispatch = useDispatch()
    const [productsearch, setProductsearch] = useState('')
    const [productList, setProductList] = useState(null)
    const [currentPage, setCurrentPage] = useState(null)
    const [totalPages, setTotalPages] = useState(null)
    const Auth = useSelector(({auth}) => {
        return auth.login.success ? auth.user : false
    })
    const vendorData = useSelector(({vendor}) => {
        return vendor ? vendor.vendor.vendorProducts : false
    })
    useEffect(() => {
        if(Auth){
            dispatch(actions.getVendorProducts(Auth.id))
        }
    }, [])
    useEffect(() => {
        if(vendorData){
            setProductList(vendorData)
        }
    },[vendorData])
    const onProductSearch = (searchproduct) => {
        console.log(searchproduct)
    }

    const onPageChanged = data => {
        const { currentPage, totalPages, pageLimit } = data;
      };


    const onDeleteProduct = (id) => {
        if(id){
            dispatch(actions.deleteProduct(id))
        }
    }
    if(productList === null ){
        return (
            <div id="preloader">
            <Loader type="Puff" color="#04d39f" height={100} width={100} />
            </div>
        )
    }
        return (
            <div>
                <AdminHeader />
                <div className="section-ptb">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="mb-0">
                                    <h4>Product List</h4>
                                </div>
                                <div className="mb-4">
                                    <form>
                                        <div class="form-group mb-0">
                                            <input type="text" class="form-control" placeholder="Search product" value={productsearch} onChange={(e)=>{onProductSearch(e.target.value)}}></input>
                                        </div>
                                    </form>
                                </div>
                                <div className="mb-0 mb-md-4">
                                {productList.length > 0 ?
                                    <Row className="products products-loop grid ciyashop-products-shortcode pgs-product-list">
                                            {productList.map((product, index) =>
                                                        <AdminproductList product={product} key={index} deleteproduct={()=>onDeleteProduct(product.id)} />
                                                )
                                            }
                                        </Row>
                                    :
                                        <Row className="products products-loop grid ciyashop-products-shortcode">
                                            <div className="col-sm-12 text-center  mt-4 mt-md-5" >
                                                <img src={require(`../../assets/images/empty-search.jpg`)} className="img-fluid mb-4" />
                                                <h3>Sorry! No products were found matching your selection!    </h3>
                                                <p>Please try to other words.</p>
                                            </div>
                                        </Row>
                                    }
                            </div>
                                <div className="row mt-md-3">
                                <div className="col-12">
                                {productList.length > 12 ?
                                        <div>
                                         <Pagination
                                            totalRecords={productList.length}
                                            pageLimit={12}
                                            onPageChanged={onPageChanged}
                                            IsDeleteProcess={false}
                                        />
                                        </div>
                                        :
                                        <div style={{display:'none'}}>
                                            <Pagination
                                            totalRecords={productList.length}
                                            pageLimit={12}
                                            onPageChanged={onPageChanged}
                                            IsDeleteProcess={false}
                                            />
                                        </div>
                                }
                                </div>
                                </div>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </div>
        )
}
export default AdminProduct;
