/**
 *  Report Page
 */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Container } from 'reactstrap';
import TransactionList from '../../api/TransactionList.json';
import TransferList from '../../api/Transfer.json';
import ExpenseCategoryList from '../../api/ExpenseCategory.json';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import CanvasJSReact from '../../assets/canvasjs.react';
import AdminHeader from './Adminheader.js';
import Loader from 'react-loader-spinner';
import * as Actions from 'store/actions'
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

function Reports() {
  const dispatch = useDispatch()
  const [invoices, setInvoices] = useState(null)
  const [array, setArray] = useState([])
  const auth = useSelector(({auth}) => {
    return auth.login.success ? auth.user : false
});
const invData = useSelector(({vendor}) => vendor.vendor.vendorOrders)
useEffect(() => {
  window.scrollTo(0, 0)
  if(auth){
      dispatch(Actions.getVendorOrders(auth.id))
  }
  },[])
  useEffect(() => {
    if(invData){
      setInvoices(invData)
    }
    },[invData])
    if(invoices === null){
      return (
          <div id="preloader">
              <Loader type="Puff" color="#04d39f" height={100} width={100} />
          </div>
      )
  }
  for(let i= 0; i< invoices.length; i++){
    array.push({ x : new Date(invoices[i].Year, invoices[i].Month, invoices[i].Day+i), y : 500*i})
  }
  console.log(array)

    const Monthoptions = {
      animationEnabled: true,
      title: {
        horizontalAlign: "left"
      },
      axisX: {
        title: "date",        
        valueFormatString: "DDD DD MMM",
      },
      axisY: {
        title: "Sales (in USD)",
        prefix: "$",
        includeZero: false
      },
      data: [{
        lineColor: "#03d39f",
        markerColor: "#03d39f",
        yValueFormatString: "#Sales",
        xValueFormatString: "DDD DD MMM",
        type: "spline",
        dataPoints: array
      }]
    }

    const Weekhoptions = {
      animationEnabled: true,
      title: {
        horizontalAlign: "left"
      },
      axisX: {
        valueFormatString: "DDD DD MMM"
      },
      axisY: {
        title: "Sales (in USD)",
        prefix: "$",
        includeZero: false
      },
      data: [{
        lineColor: "#03d39f",
        markerColor: "#03d39f",
        yValueFormatString: "$#,###",
        xValueFormatString: "DDD DD MMM",
        type: "spline",
        dataPoints: [
          { x: new Date(2019, 5, 16), y: 34875 },
          { x: new Date(2019, 5, 17), y: 35984 },
          { x: new Date(2019, 5, 18), y: 78547 },
          { x: new Date(2019, 5, 19), y: 42400 },
          { x: new Date(2019, 5, 20), y: 35687 },
          { x: new Date(2019, 5, 21), y: 46584 }

        ]
      }]
    }
    const Yearoptions = {
      animationEnabled: true,
      title: {
        horizontalAlign: "left"
      },
      axisX: {
        interval: 1,
        intervalType: "year"
      },
      axisY: {
        title: "Sales (in USD)",
        prefix: "$",
        includeZero: false
      },
      data: [{
        lineColor: "#03d39f",
        markerColor: "#03d39f",
        yValueFormatString: "$#,###",
        xValueFormatString: "YYYY",
        type: "spline",
        dataPoints: [
          { x: new Date(2014, 0), y: 24875 },
          { x: new Date(2015, 0), y: 24875 },
          { x: new Date(2016, 0), y: 25984 },
          { x: new Date(2017, 0), y: 68547 },
          { x: new Date(2018, 0), y: 32400 },
          { x: new Date(2019, 0), y: 25687 },

        ]
      }]
    }

    return (
      <> <AdminHeader />
      <div className="section-ptb">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="d-sm-flex reports-tab w-100 mb-0">
                <h4>Reports</h4>
              </div>
            </Col>
          </Row>
          <div>
            <div>
               <Tabs>
                <TabList>
                  <Tab>Week</Tab>
                  <Tab>Month</Tab>
                  <Tab>Year</Tab>
                </TabList>
                <TabPanel>
                  <CanvasJSChart options={Weekhoptions} />
                </TabPanel>
                <TabPanel>
                  <CanvasJSChart options={Monthoptions} />
                </TabPanel>
                <TabPanel>
                  <CanvasJSChart options={Yearoptions} />
                </TabPanel>
              </Tabs>
            </div>
            <div className="reports-table">
            <Tabs>
              <TabList>
                <Tab>Transaction List</Tab>
                <Tab>Transfer Report</Tab>
                <Tab>Expense Category</Tab>
              </TabList>

              <TabPanel>
                <div class="tab-pane fade show active" id="transaction-list" role="tabpanel" aria-labelledby="transaction-list-tab">
                  <div className="table-responsive">
                    <table class="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Transaction Id</th>
                          <th scope="col">Date</th>
                          <th scope="col">Type</th>
                          <th scope="col">Account</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Debit</th>
                          <th scope="col">Balance</th>
                        </tr>
                      </thead>
                      <tbody>
                        {TransactionList.map((T, index) =>
                          <tr key = {index}>
                            <td>{T.TransactionID}</td>
                            <td>{T.Date}</td>
                            <td className="text-success">{T.Type}</td>
                            <td>{T.Account}</td>
                            <td>{T.Amount}</td>
                            <td>{T.Debit}</td>
                            <td>{T.Balance}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div class="tab-pane fade show active" id="transaction-list" role="tabpanel" aria-labelledby="transaction-list-tab">
                  <div className="table-responsive">
                    <table class="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Transaction Id</th>
                          <th scope="col">Date</th>
                          <th scope="col">Account</th>
                          <th scope="col">Type</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Balance</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {TransferList.map((T, index) =>

                          <tr>
                            <td>{T.TransactionID}</td>
                            <td>{T.Date}</td>
                            <td>{T.Account}</td>
                            <td className="text-success">{T.Type}</td>
                            <td>{T.Amount}</td>
                            <td>{T.Balance}</td>
                            <td>{T.Status}</td>

                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div class="tab-pane fade show active" id="transaction-list" role="tabpanel" aria-labelledby="transaction-list-tab">
                  <div className="table-responsive">
                    <table class="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Item No</th>
                          <th scope="col">Date</th>
                          <th scope="col">Type</th>
                          <th scope="col">Company Name</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ExpenseCategoryList.map((T, index) =>
                          <tr>
                            <td>{T.ItemNo}</td>
                            <td>{T.Date}</td>
                            <td>{T.Type}</td>
                            <td>{T.CompanyName}</td>
                            <td>{T.Amount}</td>
                            <td>{T.Status}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
          </div>

        </Container>

      </div>
      </>
    )
}
export default Reports;
