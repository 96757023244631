import {combineReducers} from 'redux';
import fuse from './fuse';
import vendor from './vendor';
import auth from '../../auth/store/reducers';

const createReducer = (asyncReducers) =>
    combineReducers({
        auth,
        fuse,
        vendor,
        ...asyncReducers
    });

export default createReducer;