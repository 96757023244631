import axios from 'axios';
import { toast } from 'react-toastify';
import Domain from 'config.js'
export const GET_VENDOR_PRODUCTS = '[VENDOR APP] GET VENDOR PRODUCTS';
export const UPDATE_VENDOR_PRODUCT = '[VENDOR APP] UPDATE_VENDOR_PRODUCT';
export const VENDOR_MATERIALS = '[VENDOR APP] VENDOR_MATERIALS';
export const ADD_VENDOR_PRODUCT = '[VENDOR APP] ADD_VENDOR_PRODUCT';
export const DELETE_PRODUCT_IMAGE = '[VENDOR APP] DELETE_PRODUCT_IMAGE';
export const VENDOR_PRODUCT_DETAIL = '[VENDOR APP] VENDOR_PRODUCT_DETAIL';
export const DELETE_PRODUCT = '[VENDOR APP] DELETE_PRODUCT';
export const GET_VENDOR_ORDERS = '[VENDOR APP] GET_VENDOR_ORDERS';

export function getVendorProducts(id) {
    const request = axios.get(`${Domain}/v1/vendor/vendorproducts`, {
        params: {
            id
        }
    });

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: GET_VENDOR_PRODUCTS,
                payload: response.data.products
            })
        }
        )
    )
}
export function vendorMaterials() {
    const request = axios.get(`${Domain}/v1/buyer/categories`);

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: VENDOR_MATERIALS,
                payload: response.data.materials
            })
        }
        )
    )
}


export function addNewProduct(data) {
    return (dispatch, getState) => {
        const request = axios.post(`${Domain}/v1/vendor/addproduct`, data);

    return request.then((response) => {
            return dispatch({
                type: ADD_VENDOR_PRODUCT,
            })
        }
        ).then(() => toast.success('Product added Successfully'))
        .then(() => window.location.reload())
        .catch(error => {
            console.log(error)
            toast.warn('Cannot Add Product')
        })
    }
}
export function VendorProductDetail(id) {
    const request = axios.get(`${Domain}/v1/vendor/vendorproductdetail`, {
        params: {
            id
        }
    })
    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: VENDOR_PRODUCT_DETAIL,
                payload: response.data.product
            })
        }
        ).catch(error => console.log(error))
    )
}

export function updateProductVendor(data) {
    return (dispatch, getState) => {
        const request = axios.put(`${Domain}/v1/vendor/updateproduct`, data);

    return request.then((response) => {
            return dispatch({
                type: UPDATE_VENDOR_PRODUCT,
            })
        }
        ).then(() => window.location.reload())
        .then(() => toast.success('Successfully Updated'))
        .catch(() => toast.warn('Cannot Update'))
    }
}

export function deleteProImage(id) {
    return (dispatch, getState) => {
        const request = axios.delete(`${Domain}/v1/vendor/product/image`, {
            params : {id}
        });

    return request.then((response) => {
            return dispatch({
                type: DELETE_PRODUCT_IMAGE,
            })
        }
        ).then(() => toast.success('Image Successfully Deleted'))
        .then(() => window.location.reload())
        .catch(error => console.log(error))
    }
}
export function deleteProduct(id) {
    return (dispatch, getState) => {
        const request = axios.delete(`${Domain}/v1/vendor/deleteproduct`, {
            params : {id}
        });

    return request.then((response) => {
            return dispatch({
                type: DELETE_PRODUCT,
            })
        }
        ).then(() => toast.success('Successfully Deleted'))
        .then(() => window.location.reload())
        .catch(error => console.log(error))
    }
}

export function getVendorOrders(id) {
    const request = axios.post(`${Domain}/v1/vendor/orders/active`, { id, page: 1, limit: 25});

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: GET_VENDOR_ORDERS,
                payload: response.data.data
            })
        }
        )
    )
}