import axios from 'axios';
import Domain from 'config.js'
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTBYCAT = 'GET_PRODUCTBYCAT';
export const GET_PRODUCTDETAIL = 'GET_PRODUCTDETAIL';
export const NULL_PRODUCTDETAIL = 'NULL_PRODUCTDETAIL';

export function getProducts() {
    const request = axios.post(`${Domain}/v1/buyer/products/latest`, {
        'page': 1,
        'limit': 25
    });

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: GET_PRODUCTS,
                payload: response.data.products
            })
        }
        )
    )
}
export function getProductDetail(id) {
    const request = axios.get(`${Domain}/v1/buyer/getProductDetail`, {
        params : {id}
    });

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: GET_PRODUCTDETAIL,
                payload: response.data.product
            })
        }
        )
    )
}


export function getProductsByCat(ids, price, SearchValue) {
    const request = axios.post(`${Domain}/v1/buyer/products/material/${ids}`, {
        page: 1, limit: 25, ids, price, SearchValue
    });

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: GET_PRODUCTBYCAT,
                payload: response.data.products
            })
        }
        )
    )
}