/**
 *  Admin Site Product Add
 */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col,Container, FormGroup, Label, Input } from 'reactstrap';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import ImageUploader from 'react-images-upload';
import AdminHeader from '../Adminheader';
import * as Actions from 'store/actions'

function Productadd(){
    const dispatch = useDispatch()
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };
    const productslider = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1
      };
    const productdata = {
        Product_single: "product-single.jpg",
        product_gallery: [
           "product-single.jpg",
           "product-single.jpg",
           "product-single.jpg",
           "product-single.jpg"
        ]
     }
     const Category = useSelector(({vendor}) => vendor.vendor.Categories)
     const auth = useSelector(({auth}) => {
         return auth.login.success ? auth.user : false
     })
    const [newForm, setNewForm] = useState(null)
    const [Cat, setCat] = useState(null)
    const [newpic, setNewPIc] = useState([])
    useEffect(() => {
        setCat(Category)
    },[Category])
    const formChange =(e) => {
        setNewForm({
            ...newForm,
            [e.target.name] : e.target.value
        })
    }
    const config = {     
        headers: { 'content-type': 'multipart/form-data' }
    }

    const Uploadimage = (e) => {
        setNewPIc([...newpic, e])
        }
        const formSubmit = (e) => {
            e.preventDefault()
            const fx = new FormData();
            fx.append('vendor_id', auth.id)
            fx.append('name', newForm.name)
            fx.append('price', newForm.price)
            fx.append('salePrice', newForm.salePrice)
            fx.append('details', newForm.details)
            fx.append('maxQuantity', newForm.stock)
            fx.append('material_id', newForm.category)
            fx.append('brand', newForm.brand)
            fx.append('material_type_id', 10)
            fx.append('shippingCost', newForm.shippingCost)
            fx.append('quality', 15)
            for(let i=0; i<newpic.length; i++){
                fx.append('image', newpic[i][0], 'proinmage'+i)
            }
            dispatch(Actions.addNewProduct(fx,config))
        }
        return(
                <div>
                    <AdminHeader />
                    <div className="site-content">
                        <div className="content-wrapper section-ptb">
                            <Container>
                                <div className="product-content-top single-product single-product-edit">
                                    <Row>
                                        <div className="product-top-left col-xl-5 col-md-6">
                                            <div className="product-top-left-inner">
                                                <div className="ciyashop-product-images">
                                                <div className="ciyashop-product-images-wrapper ciyashop-gallery-style-default ciyashop-gallery-thumb_position-bottom ciyashop-gallery-thumb_vh-horizontal">
                                                    <div className="ciyashop-product-gallery ciyashop-product-gallery--with-images slick-carousel">
                                                    <Slider {...settings} className="ciyashop-product-gallery__wrapper popup-gallery">
                                                        <div className="ciyashop-product-gallery__image">
                                                                <img src={require(`../../../assets/images/${productdata.Product_single}`)}   className="img-fluid" />
                                                        </div>

                                                    </Slider>

                                                    </div>
                                                    <div className="ciyashop-product-thumbnails">
                                                        <Slider {...productslider} className="ciyashop-product-thumbnails__wrapper">
                                                            {productdata.product_gallery.map((pictureimage,index) =>
                                                                <div key={index}>
                                                                    <div className="ciyashop-product-thumbnail__image">
                                                                        <a href="#">
                                                                            <img src={require(`../../../assets/images/${pictureimage}`)}  className="img-fluid" />
                                                                        </a>
                                                                        <div className="d-flex justify-content-center image-content align-items-center">
                                                                            <ImageUploader
                                                                                buttonText=""
                                                                                withIcon={false}
                                                                                withPreview={true}
                                                                                onChange={Uploadimage}
                                                                                imgExtension={['.jpg', '.jpeg', '.png']}/>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            )}
                                                        </Slider>
                                                    </div>
                                                    <div className="clearfix" />
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                       <div className="product-top-right col-xl-7 col-md-6">
                                            <div className="product-top-right-inner">
                                                <form onSubmit={formSubmit} className="summary entry-summary">
                                                        <FormGroup className="edit-icon">
                                                            <Input type="text" name = 'name' className="form-control product_title" placeholder="Product Name" onChange= {e=> formChange(e)} />
                                                        </FormGroup>
                                                        <FormGroup className="edit-icon">
                                                            <Input type="text" name = 'price' className="form-control price" placeholder="Product Price" onChange= {e=> formChange(e)}  />
                                                        </FormGroup>
                                                        <FormGroup className="edit-icon">
                                                            <Input type="text" name = 'salePrice' className="form-control price" placeholder="Discounted Price" onChange= {e=> formChange(e)}  />
                                                        </FormGroup>
                                                        <FormGroup className="edit-icon">
                                                            <Input  type="textarea" name = 'details' className="form-control" rows="3" placeholder="Product Description" onChange= {e=> formChange(e)} />
                                                        </FormGroup>
                                                        <FormGroup className="edit-icon">
                                                            <Input type="text" name = 'brand' className="form-control price" placeholder="Brand" onChange= {e=> formChange(e)}  />
                                                        </FormGroup>
                                                         <FormGroup>
                                                        <Label className="title pl-0">Category</Label>
                                                        <select name = 'category' class="form-control"  onChange= {e=> formChange(e)}>
                                                            {Cat && Cat!==null ?
                                                            Cat.map(cat => (
                                                            <option value={cat.id} key={cat.id}>{cat.name}</option>
                                                            )) : <option value="cat.id">wait</option>}
                                                        </select>
                                                        </FormGroup>
                                                        <FormGroup>
                                                        <Label className="title pl-0">Shipping Cost</Label>
                                                        <input type="text" name = 'shippingCost' class="form-control" placeholder="Shipping Cost" onChange= {e=> formChange(e)} ></input>
                                                        </FormGroup>
                                                        <FormGroup>
                                                        <Label className="title pl-0">Product Stock</Label>
                                                        <input type="text" name = 'stock' class="form-control" placeholder="Product Stock" onChange= {e=> formChange(e)} ></input>
                                                        </FormGroup>
                                                        <button type ='submit' href="#" class="btn btn-primary mb-2 mr-2"> Save </button>
                                                        <Link to="/admin-panel/Product" class="btn btn-danger mb-2"> Cancel </Link>
                                                </form>
                                            </div>
                                        </div>
                                    </Row>
                                    </div>
                            </Container>
                        </div>
                    </div>
            </div>
        )
}
export default Productadd;
