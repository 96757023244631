
import jwtService from 'services/jwtService';
import { setUserData } from './user.actions';
import { toast } from 'react-toastify';

export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const MODAL_TOGGLE = 'MODAL_TOGGLE';

export function submitLogin({ email, password }) {
    console.log(email)
    return (dispatch) =>
    jwtService.signInWithEmailAndPassword(email, password)
        .then((user) => {
            dispatch(setUserData(user));

            return dispatch({
                type: LOGIN_SUCCESS
            });
        }
        )
        .catch(error => {
            toast.error(error)
            return dispatch({
                type: LOGIN_ERROR,
                payload: error
            });
        });
}
export function submitLoginVendor({ email, password }) {
    return (dispatch) =>
    jwtService.signInVendor(email, password)
        .then((user) => {
            dispatch(setUserData(user));

            return dispatch({
                type: LOGIN_SUCCESS
            });
        }
        )
        .catch(error => {
            toast.error(error)
            return dispatch({
                type: LOGIN_ERROR,
                payload: error
            });
        });
}

export function SetLogin() {
    return (dispatch) => {
        return dispatch({
            type: LOGIN_SUCCESS
        });
    }
}
export function modelToggle(){
    return (dispatch) => {
        return dispatch({
            type : MODAL_TOGGLE
        })
    }
}
