import {combineReducers} from 'redux';
import cart from './cart.reducer';
import Products from './products.reducer';
import Orders from './orders.reducer'
import Category from './category.reducer'

const fuseReducers = combineReducers({
    cart,
    Products,
    Category,
    Orders,
});

export default fuseReducers;
