import { toast } from 'react-toastify';
import axios from 'axios';
import Domain from 'config.js'
export const ADD_CART = 'ADD CART';
export const REMOVE_CART = 'REMOVE CART';
export const PLACE_ORDER = '[LANDING PAGE] PLACE ORDER';
export function addToCart(data) {
    return {
        type: ADD_CART,
        payload: data
    }
}
export function removeFromCart(id) {
    return {
        type: REMOVE_CART,
        payload: id
    }
}
export function placeOrder(data) {
    const request = axios.post(`${Domain}/v1/buyer/placeOrder`, data);

    return (dispatch) => (
        request.then((response) => {
            toast.success(response.data.msg)
        }
        ).then(() => {
            return dispatch({
                type: PLACE_ORDER,
            })
        }).catch(error => {
            console.log(error.response)
            if (error.response) {
                toast.warn(error.response.data.msg)
            }
            else {
                toast.warn('Problem Connecting Server')
            }
        })
    )
}

