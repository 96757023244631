/**
 *  Address Dislay
 */
import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col,Container } from 'reactstrap';
import Sitebar from './Sitebar';
import history from '@history'
import Loader from 'react-loader-spinner';
import Header from 'layouts/header/Header';

function Address() {
  const [userData, setUserData] = useState(null)
  const user = useSelector(({auth}) => {
    return auth.login.success ? auth.user : false
  })
  useEffect(() => {
    window.scrollTo(0,0);
      setUserData(user)
  },[user])
  if(user === false){
    history.push({
      pathname: '/'
    })
  }
   return (
    <div>
      <Header />
    <div className="inner-intro">
      <Container>
          <Row className="intro-title align-items-center">
              <Col md={6} className="text-left">
                  <div className="intro-title-inner">
                  <h1>My Account</h1>
                  </div>
              </Col>
              <Col md={6}  className="text-right">
                  <ul className="ciyashop_breadcrumbs page-breadcrumb breadcrumbs">
                  <li className="home">
                      <span>
                      <Link className="bread-link bread-home" to="/">Home</Link>
                      </span>
                  </li>
                  <li><span>My Account</span></li>
                  </ul>
              </Col>
          </Row>
      </Container>
    </div>
    <div className="section-ptb">
    <Container>
     <Row>
       <Sitebar />
      <Col lg={9} className="mt-4 mt-lg-0">
        {userData ===null ?
        <div className= 'text-center'>
        <Loader type="Puff" color="#04d39f" height={100} width={100}/>
        </div> :
        <Row>
        <Col md={6}>
          <div className="woocommerce-Address">
            <div className="woocommerce-Address-title">
              <h5 className="mb-0">Billing address</h5>
              <Link className="edit" to="/Account/Addressedit">Edit</Link>
            </div>
            <div className="woocommerce-Address-info mt-4">
              <ul className="list-unstyled mb-0">
                <li><strong>{userData.data.firstName} {userData.data.lastName}</strong></li>
                <li><strong>{userData.data.address}</strong></li>
                <li><strong>{userData.data.city}</strong></li>
                <li><strong>{userData.data.country}</strong></li>
              </ul>
            </div>
          </div>
        </Col>
        <Col md={6} className="mt-4 mt-md-0">
          <div className="woocommerce-Address">
            <div className="woocommerce-Address-title">
              <h5 className="mb-0">Shipping address</h5>
              <Link className="edit" to="/Account/Addressedit">Edit</Link>
            </div>
            <div className="woocommerce-Address-info mt-4">
              <ul className="list-unstyled mb-0">
              <li><strong>{userData.data.firstName} {userData.data.lastName}</strong></li>
                <li><strong>{userData.data.address}</strong></li>
                <li><strong>{userData.data.city}</strong></li>
                <li><strong>{userData.data.country}</strong></li>
              </ul>
            </div>
          </div>
        </Col>
        </Row>}
      </Col>
    </Row>
    </Container>
    </div>
    </div>
        )
}
export default Address;
