/**
 * Admin Profile Detail
 */
import React from 'react';
import { Row, Col } from 'reactstrap';
import Domain from 'config.js'
function AdminProfileDetail(props){
        return(
            <Row>
                <Col lg={12}>
                <div className="admin-profile">
                    <div className="admin-profile-image">
                    <img className="img-fluid rounded-circle" src={`${Domain}${props.Profile.photoURL}`} alt="profile"/>	
                    </div>
                    <div className="admin-profile-info">
                        <h6 className="mb-0">{props.Profile.firstName+' '+props.Profile.lastName}</h6>
                        <span className="text-dark">{props.Profile.email}</span>
                    </div>
                   {props.Profile.phone != '' ? 
                        <div className="admin-profile-number ml-auto">
                            <strong className="h2"> <i className="fa fa-phone-square pr-2"></i>{props.Profile.phone}</strong>
                        </div>
                    :
                        null
                    }
                    
                </div>
                </Col>  
            </Row>
        )
}
export default AdminProfileDetail;
