import * as Actions from '../../actions/products.actions';
import * as moment from 'moment';

const initialState = {
    TopProducts: null,
    productDetail: null,
    productsByCat: []
};

const ProductsReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_PRODUCTS:
            {
                let resFormattedMapped = []
                if (action.payload) {
                    let res = action.payload
                    resFormattedMapped = res.map(product => (
                        {
                            id: product.id,
                            name: product.name,
                            pictures: product.productimages.map(pix => (
                                pix.image
                            )),
                            stock: 16,
                            price: product.price,
                            shippingCost: product.shippingCost,
                            price : product.price,
                            salePrice: product.salePrice,
                            brand : product.brand,
                            description: product.details,
                            rating: product.ratings,
                            size: product.size,
                            stock: product.maxQuantity,
                            category: product.productmaterial.name,
                            created_at: moment(product.createdAt).format('MMM DD YYYY h:mm A'),
                        }
                    ))
                }
                return {
                    ...state,
                    TopProducts: resFormattedMapped,
                };
            }
        case Actions.GET_PRODUCTDETAIL:
            {
                let resFormattedMapped = {}
                if (action.payload) {
                    let product = action.payload
                    resFormattedMapped = {
                            id: product.id,
                            name: product.name,
                            pictures: product.productimages.map(pix => (
                                pix.image
                            )),
                            stock: 16,
                            price: product.price,
                            shippingCost: product.shippingCost,
                            price : product.price,
                            salePrice: product.salePrice,
                            brand : product.brand,
                            description: product.details,
                            rating: product.ratings,
                            size: product.size,
                            stock: product.maxQuantity,
                            category: product.productmaterial.name,
                            created_at: moment(product.createdAt).format('MMM DD YYYY h:mm A'),
                        }
                }
                return {
                    ...state,
                    productDetail: resFormattedMapped,
                };
            }
            case Actions.GET_PRODUCTBYCAT:
            {
                let resFormattedMapped = []
                if (action.payload) {
                    let res = action.payload
                    resFormattedMapped = res.map(product => (
                        {
                            id: product.id,
                            name: product.name,
                            pictures: product.productimages.map(pix => (
                                pix.image
                            )),
                            stock: 16,
                            price: product.price,
                            shippingCost: product.shippingCost,
                            price : product.price,
                            salePrice: product.salePrice,
                            brand : product.brand,
                            description: product.details,
                            rating: product.ratings,
                            size: product.size,
                            stock: product.maxQuantity,
                            category: product.productmaterial.name,
                            created_at: moment(product.createdAt).format('MMM DD YYYY h:mm A'),
                        }
                    ))
                }
                return {
                    ...state,
                    productsByCat: resFormattedMapped,
                };
            }
        default:
            {
                return state;
            }
    }
};

export default ProductsReducer;
