import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Router, Redirect } from "react-router-dom";
// pages for this kit
import {Auth} from './auth';
import { Provider } from 'react-redux'
import store from "./store/index";
import history from './@history'
import HomePage from './component/home'
import './styles.js'
import LoginPage from "component/loginPage";
import ShopingCart from "component/shop/ShopingCart";
import ProductDetail from "component/shop/product-detail";
import AccountProfile from "component/Account/AccountProfile";
import OrderHistory from "component/Account/OrderHistory";
import Address from "component/Account/Address";
import AccountProfileedit from "component/Account/AccountProfileedit";
import Addressedit from "component/Account/Addressedit";
import ShopPage from 'component/shop/index'
import CheckOut from "component/shop/CheckOut";
import SuccessScreen from "component/Account/SuccessScreen";
import VendorLogin from "component/Vendor/VendorLogin/VendorLogin";
import Reports from "component/Vendor/Reports";
import AdminproductList from "component/Vendor/AdminProductList";
import Productadd from "component/Vendor/Product/Productadd";
import Invoices from "component/Vendor/Invoices";
import Profile from "component/Vendor/Profile";
import Profileedit from "component/Vendor/Profileedit";
import Productedit from "component/Vendor/Product/Productedit";

ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Auth>
            <Router history={history}>
              <Route path="/" exact render={props => <HomePage {...props} />} />
              <Route path="/Login" exact render={props => <LoginPage {...props} />} />
              <Route path="/ShopingCart" exact render={props => <ShopingCart {...props} />} />
              <Route path="/shop" exact render={props => <ShopPage {...props} />} />
              <Route path={`/shop/productDetail/:id`} exact render={props => <ProductDetail {...props} />} />
              <Route path="/checkOut" exact render={props => <CheckOut {...props} />} />
              <Route path="/SuccessScreen" exact render={props => <SuccessScreen {...props} />} />


              <Route path="/vendor/login" exact render={props => <VendorLogin {...props} />} />
              <Route path="/vendor/dashboard" exact render={props => <Reports {...props} />} />
              <Route path="/vendor/invoices" exact render={props => <Invoices {...props} />} />
              <Route path="/vendor/Product" exact render={props => <AdminproductList {...props} />} />
              <Route path={`/vendor/Product-edit/:id`} exact render={props => <Productedit {...props} />} />
              <Route path="/vendor/product-add" exact render={props => <Productadd {...props} />} />
              <Route path="/vendor/profile" exact render={props => <Profile {...props} />} />
              <Route path="/vendor/profileedit" exact render={props => <Profileedit {...props} />} />

              <Route path="/Account/AccountProfile" exact render={props => <AccountProfile {...props} />} />
              <Route path="/Account/AccountProfileedit" exact render={props => <AccountProfileedit {...props} />} />
              <Route path="/Account/OrderHistory" exact render={props => <OrderHistory {...props} />} />
              <Route path="/Account/Address" exact render={props => <Address {...props} />} />
              <Route path="/Account/Addressedit" exact render={props => <Addressedit {...props} />} />

              {/* <Redirect to="/"/> */}
            </Router>
          </Auth>
        </Switch>
      </BrowserRouter>
    </Provider>,
  document.getElementById("root")
);

