import * as Actions from '../../actions';
import * as moment from 'moment';
import Domain from 'config.js'

const initialState = {
    vendorProducts: null,
    Categories: null,
    productDetail : null,
    vendorOrders : null
};

const VendorPageReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_VENDOR_PRODUCTS:
            {
                let resFormattedMapped = []
                if (action.payload) {
                    let res = action.payload
                    resFormattedMapped = res.map(product => (
                        {
                            id: product.id,
                            name: product.name,
                            pictures: product.productimages.map(pix => (
                                {id: pix.id, image: pix.image}
                            )),
                            stock: 16,
                            price: product.price,
                            discount: 20,
                            salePrice: product.price - 20,
                            description: product.details,
                            rating: product.ratings,
                            size: product.size,
                            category: product.productmaterial.name,
                            created_at: moment(product.createdAt).format('MMM DD YYYY h:mm A'),
                        }
                    ))
                }
                return {
                    ...state,
                    vendorProducts: resFormattedMapped,
                };
            }
            case Actions.VENDOR_PRODUCT_DETAIL:
            {
                let product = action.payload
                const pro =  {
                    id: product.id,
                    name: product.name,
                    pictures: product.productimages.map(pix => (
                        {id: pix.id, image: pix.image}
                    )),
                    stock: 16,
                    price: product.price,
                    discount: 20,
                    salePrice: product.price - 20,
                    description: product.details,
                    rating: product.ratings,
                    size: product.size,
                    category: product.productmaterial,
                    created_at: moment(product.createdAt).format('MMM DD YYYY h:mm A'),
                }

                return {
                    ...state,
                    productDetail : pro
                }
            }
            case Actions.VENDOR_MATERIALS:
            {
                let resFormattedMapped = []
                if (action.payload) {
                    let res = action.payload
                    resFormattedMapped = res.map(mat => (
                        {
                            id: mat.id,
                            name: mat.name,
                            avatar: `${Domain}${mat.image}`,
                            subcategories: mat.subcategories,
                            created_at: moment(mat.datetime).format('MMM DD YYYY h:mm A')
                        }
                    ))
                }
                return {
                    ...state,
                    Categories: resFormattedMapped,
                };
            }
            case Actions.GET_VENDOR_ORDERS:
            {
                let resFormattedMapped = []
                if (action.payload) {
                    let res = action.payload
                    resFormattedMapped = res.map(order => (
                        {
                            id: order.id,
                            invoiceid: `pk-01-${order.id}`,
                            buyer: order.customerorder.fullName,
                            buyerPhone: order.customerorder.phone,
                            buyerEmail: order.customerorder.email,
                            date: moment(order.createdAt).format('DD MM YYYY'),
                            Year: moment(order.createdAt).format('YYYY'),
                            Month: moment(order.createdAt).format('MM'),
                            Day: moment(order.createdAt).format('DD'),
                            status: order.orderStatus,
                            payment: order.paymentMethod,
                            price: order.totalPrice,
                            deliveryAddress: order.deliveryAddress,
                            viewStatus: order.viewStatus,
                            CustomerId: order.customerorder.id,
                            products: order.orderid.map(pro => (
                                {  productOrderId: pro.id,
                                    quantity: pro.quantity,
                                    shipping: pro.shipping,
                                    productName: pro.productorder.name,
                                    salePrice: pro.productorder.salePrice,
                                    pictures: pro.productorder.productimages[0].image
                                }
                            ))
                        }
                    ))
                }
                return {
                    ...state,
                    vendorOrders: resFormattedMapped,
                    errorMessage: ''
                };
            }
            default:
            {
                return state;
            }
            
    }
}

export default VendorPageReducer;