/**
 *  Shop Checkout Page
 */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col,Container, Input } from 'reactstrap';
import Loader from 'react-loader-spinner';
import * as AppActions from 'store/actions'
import * as authAction from 'auth/store/actions'
import Header from 'layouts/header/Header';
import Footer from 'layouts/footer/Footer';

function CheckOut(props) {
    const dispatch = useDispatch();
    const [fieldvalue, setFieldvalue] = useState(null)
    const [errors, setErrors] = useState({})
    const userData = useSelector(({auth}) => {
        return auth.login.success ? auth.user : false
    })

    useEffect(() => {
        setFieldvalue(userData.data)
    }, [userData])
    const ReadCartItems = () => {
        var cart = JSON.parse(localStorage.getItem("LocalCartItems"));
        if (cart == null) {
            props.history.push(`/`)
        } 
        return cart
    }
    const ReadShippingCost = () => {
        var shippingCost = JSON.parse(localStorage.getItem("TotalShippingCharge"));
        return shippingCost
    }
    const onCheckOutSubmit = (e) => {
        e.preventDefault();
        if(handleValidation()){
            var total = 0;
            ReadCartItems().map(pro => {
                return total +=(pro.Rate * pro.Qty)
            })
            let productIds = ReadCartItems().map(product => {
                return {
                    id: product.ProductID,
                    quantity: product.Qty,
                    rate : product.Rate,
                    shipping : product.shippingCost
                }
            })
            const placeOrder = {
                customer_id : userData.id,
                deliveryAddress : fieldvalue.address,
                totalPrice: total,
                deliveryToSameAddress : 1,
                paymentMethod : 'Cash on delivery',
                products: productIds
            }
            placeOrder.firstName = fieldvalue.firstName
            placeOrder.lastName = fieldvalue.lastName
            placeOrder.phone = fieldvalue.phone
            dispatch(AppActions.placeOrder(placeOrder));
            localStorage.setItem("FinalCheckoutCartItems",localStorage.getItem("LocalCartItems"));
            localStorage.setItem("finaly",JSON.stringify(placeOrder));
            localStorage.removeItem("LocalCartItems");
            props.history.push(`/SuccessScreen`)
        }
      }



    const handleValidation = () => {
        let formIsValid = true;
        let errors = {}
        //First Name
        if (!fieldvalue["firstName"]) {
            formIsValid = false;
            errors["firstName"] = "Please Enter First Name";
        }

        if (typeof fieldvalue["firstName"] !== "undefined") {
            if (!fieldvalue["firstName"].match(/^[a-zA-Z]+$/)) {
                formIsValid = false;
                errors["firstName"] = "Please Enter Only Letter";
            }
        }


        //Last Name
        if (!fieldvalue["lastName"]) {
            formIsValid = false;
            errors["lastName"] = "Please Enter Last Name";
        }

        if (typeof fieldvalue["lastName"] !== "undefined") {
            if (!fieldvalue["lastName"].match(/^[a-zA-Z]+$/)) {
                formIsValid = false;
                errors["lastName"] = "Please Enter Only Letter";
            }
        }


            //streetno
            if (!fieldvalue["address"]) {
            formIsValid = false;
            errors["address"] = "Please Enter Street address";
        }

            //state
            if (!fieldvalue["city"]) {
            formIsValid = false;
            errors["city"] = "Please Enter Town / City";
        }

        if (!fieldvalue["phone"]) {
            formIsValid = false;
            errors["phone"] = "Please Enter Phone";
        }

        //Email ID
        if (!fieldvalue["email"]) {
        formIsValid = false;
        errors["email"] = "Please Enter Email ID";
        }
        setErrors( errors )
        localStorage.setItem("firstname",fieldvalue["firstname"]);
        localStorage.setItem("lastname",fieldvalue["lastname"]);
        return formIsValid;

    }

      const handleChange = (e) => {
        setFieldvalue({
            ...fieldvalue,
            [e.target.name] : e.target.value
        })
      }
        return (
            <>
            <Header />
            {userData && userData !==false ?
            <div>
            <div className="site-content">
                <div className="inner-intro">
                <Container>
                    <Row className="intro-title align-items-center">
                        <Col md={6} className="text-left">
                            <div className="intro-title-inner">
                                <h1>Checkout</h1>
                            </div>
                        </Col>
                        <Col md={6} className="text-right">
                            <ul className="ciyashop_breadcrumbs page-breadcrumb breadcrumbs">
                                <li className="home">
                                    <span>
                                        <Link className="bread-link bread-home" to="/">Home</Link>
                                    </span>
                                </li>
                                <li><span>Checkout</span></li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="content-wrapper mb-7">
                <Container>
                   <form onSubmit={onCheckOutSubmit}>
                        <Row className="mt-5">
                        <Col className="col-lg-6">
                            <Row>
                                {fieldvalue && fieldvalue !== null ?
                                <Col sm={12} >
                                <div className="billing-fields mt-5">
                                    <h3>Billing details</h3>
                                    <div className="billing-fields__field-wrapper">
                                        <div className="form-group">
                                            <label htmlFor="billing_first_name" className="">First name&nbsp;<abbr className="required" title="required">*</abbr>
                                            </label>
                                           <Input type="text" className="form-control" name="firstName" id="billing_first_name" placeholder="" value={fieldvalue.firstName} onChange={e => handleChange(e)}/>
                                           <span className="error">{errors.firstName}</span>

                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="billing_last_name" className="">Last name&nbsp;<abbr className="required" title="required">*</abbr></label>
                                            <Input type="text" className="form-control " name="lastName" id="billing_last_name" placeholder="" value={fieldvalue.lastName}  onChange={e => handleChange(e)}/>
                                            <span className="error">{errors.lastName}</span>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="billing_country" className="">Country&nbsp;<abbr className="required" title="required"></abbr></label>
                                            <select name="country" id="billing_country" className="form-control" onChange={e => handleChange(e)}>
                                                    <option value="AF">Afghanistan</option>
                                                    <option value="BH">Bahrain</option>
                                                    <option value="BD">Bangladesh</option>
                                                    <option value="FR">France</option>
                                                    <option value="IN" >India</option>
                                                    <option value="PK">Pakistan</option>
                                                    <option value="AE">United Arab Emirates</option>
                                                    <option value="GB">United Kingdom (UK)</option>
                                                    <option value="US" selected>United States (US)</option>
                                                </select>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="billing_address_1" className="">Street address&nbsp;<abbr className="required" title="required">*</abbr></label>
                                            <Input type="text" className="form-control" name="address" id="billing_address_1" placeholder="House number and street name" value={fieldvalue.address} onChange={e => handleChange(e)}/>
                                            <span className="error">{errors["address"]}</span>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="billing_city" className="">Town / City&nbsp;<abbr className="required" title="required">*</abbr></label>
                                            <Input type="text" className="form-control" name="city" id="billing_city" placeholder="" value={fieldvalue.city} onChange={e => handleChange(e)}/>
                                            <span className="error">{errors["city"]}</span>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="billing_phone" className="">Phone&nbsp;<abbr className="required" title="required">*</abbr></label>
                                           <Input type="tel" className="form-control" name="phone" id="billing_phone" placeholder="" value={fieldvalue.phone} autocomplete="tel" onChange={e => handleChange(e)}/>
                                           <span className="error">{errors["phone"]}</span>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="billing_email" className="">Email address&nbsp;<abbr className="required" title="required">*</abbr></label>
                                            <Input type="email" className="form-control" name="email" id="billing_email" placeholder="" value={fieldvalue.email} autocomplete="email username" onChange={e => handleChange(e)}/>
                                            <span className="error">{errors["email"]}</span>
                                        </div>
                                    </div>
                                </div>
                            </Col>: 
                            <div id="preloader">
                                <Loader type="Puff" color="#04d39f" height={100} width={100} />
                            </div>}
                            </Row>
                        </Col>
                        <Col lg={6} className="mt-5">
                            <h3 id="order_review_heading">Your order</h3>


                            <div id="order_review" className="checkout-review-order">
                            {(ReadCartItems() !== null && ReadCartItems().length > 0) ?

                                <table className="shop_table checkout-review-order-table">
                                    <thead>
                                        <tr>
                                            <th className="product-name">Product</th>
                                            <th className="product-total">Total</th>
                                        </tr>
                                    </thead>
                                        <tbody>

                                        {ReadCartItems().map((CartItem, index) => (
                                                <tr className="cart_item">
                                                <td className="product-name">
                                                    {CartItem.ProductName}&nbsp; <strong className="product-quantity">× {CartItem.Qty}</strong> </td>
                                                <td className="product-total">
                                                    <span className="woocs_special_price_code"><span className="Price-amount amount"><span className="Price-currencySymbol">$</span>  {CartItem.Rate * CartItem.Qty} </span></span>
                                                </td>
                                                </tr>
                                        ))}


                                    </tbody>
                                    <tfoot>
                                        <tr className="cart-subtotal">
                                            <th>Subtotal</th>
                                            <td><span className="woocs_special_price_code"><span className="Price-amount amount"><span className="Price-currencySymbol">$</span>{ReadCartItems().reduce((fr, CartItem) => fr + (CartItem.Qty * CartItem.Rate), 0)}</span></span>
                                            </td>
                                        </tr>
                                        <tr className="shipping-totals shipping">
                                            <th>Shipping</th>
                                            <td><span className="woocs_special_price_code"><span className="Price-amount amount"><span className="Price-currencySymbol">$</span>{ReadShippingCost()}</span></span></td>
                                        </tr>
                                        <tr className="order-total">
                                            <th>Total</th>
                                            <td><strong><span className="woocs_special_price_code"><span className="Price-amount amount"><span className="Price-currencySymbol">$</span>{parseFloat(parseFloat(ReadCartItems().reduce((fr, CartItem) => fr + (CartItem.Qty * CartItem.Rate), 0)) + parseFloat((ReadShippingCost() !== undefined) ? ReadShippingCost().toFixed(2) : 0)).toFixed(2)}    </span></span></strong>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            :
                                <div>No Items found</div>
                            }
                                <div id="payment" className="checkout-payment">
                                    <ul className="payment_methods methods">
                                        <li className="payment_method_paypal">
                                            <input id="payment_method_paypal" type="radio" checked disabled className="input-radio" name="payment_method" value="paypal"
                                            data-order_button_text="Proceed to PayPal"/>

                                            <label for="payment_method_paypal">
                                                PayPal <img src="https://www.paypalobjects.com/webstatic/mktg/logo/AM_mc_vs_dc_ae.jpg" alt="PayPal acceptance mark"/> 
                                            </label>
                                        </li>
                                    </ul>
                                    <div className="form-row place-order">


                                        <div className="terms-and-conditions-wrapper">
                                            <div className="privacy-policy-text">
                                                <p>Your personal data will be used to process your order, support your
                                                    experience throughout this website, and for other purposes described in
                                                    our
                                                </p>
                                            </div>
                                            <p className="form-row validate-required ml-5">
                                                <label className="form__label form__label-for-checkbox checkbox">
                                                    <Input checked disabled type="checkbox" className="form__input form__input-checkbox input-checkbox" name="terms" id="terms"/>
                                                    <span className="terms-and-conditions-checkbox-text">I have read
                                                        and agree to the website
                                                        </span>&nbsp;<span className="required">*</span>
                                                </label>
                                                <Input type="hidden" name="terms-field" value="1"/>
                                            </p>
                                        </div>

                                        <button type="submit" className="button alt" name="checkout_place_order" id="place_order" value="Place order" data-value="Place order">
                                         Place Order
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    </form>
                </Container>
            </div>

        </div>
        <Footer />
        </div> :
        <div className='mt-10'>
            <h2 className='text-center text-dark'>Please Login first to proceed with your order.</h2>
        </div>
        }
        </>

            )
}
export default CheckOut;
