/**
 * Top Salling Product Widgets
 */
import React from 'react';
import { Row, Col } from 'reactstrap';
import {Link} from 'react-router-dom';
import { toast  } from 'react-toastify';
import Domain from 'config.js'
import 'react-toastify/dist/ReactToastify.min.css';

function TopSellingProduct (props){
  const AddToCart = (ProductID,ProductName,ProductImage,Qty,Rate,shippingCost,StockStatus) => {
   var Cart = JSON.parse(localStorage.getItem("LocalCartItems"));
   if(Cart == null)
      Cart = new Array();
   let selectedProduct = Cart.find(product => product.ProductName === ProductName);
   if(selectedProduct == null){
      Cart.push({ProductID,ProductName,ProductImage,Qty,Rate,shippingCost,StockStatus});
      localStorage.removeItem("LocalCartItems");
      localStorage.setItem("LocalCartItems",JSON.stringify(Cart));
      toast.success("Item Added to Cart");
   }
   else {
      toast.warning("Item is already in Cart");
   }
}


const AddToWishList = (ProductID,ProductName,ProductImage,Qty,Rate,StockStatus) => {
   var Cart = JSON.parse(localStorage.getItem("LocalWishListItems"));
   if(Cart == null)
      Cart = new Array();

      let selectedProduct = Cart.find(product => product.ProductName === ProductName);
      if(selectedProduct == null)
      {

         Cart.push({ProductID:ProductID,ProductName:ProductName,ProductImage:ProductImage,Qty:Qty,Rate:Rate,StockStatus:StockStatus});
         localStorage.removeItem("LocalWishListItems");
         localStorage.setItem("LocalWishListItems",JSON.stringify(Cart));

         toast.success("Item Added to WishList");
      }
      else {
         toast.warning("Item is already in WishList");
      }
}
const CheckCardItem = (ID) => {
   let checkcart=false;
   var Cart = JSON.parse(localStorage.getItem("LocalCartItems"));
   if(Cart && Cart.length > 0) {
      for (const cartItem of Cart) {
         if (cartItem.ProductID === ID) {
            checkcart = true
         }
      }
   }
   return checkcart;
}
const CheckWishList = (ID) => {
   let wishlist=false;
   var Wish = JSON.parse(localStorage.getItem("LocalWishListItems"));

   if(Wish && Wish.length > 0) {
      for (const wishItem of Wish) {
         if (wishItem.ProductID === ID) {
            wishlist = true
         }
      }
   }
   return wishlist;
}

const rating = (productrat) => {
   let rat=[];
   let i = 1;
   while (i <= 5) {
         if(i<=productrat)
         {
               rat.push(<i key={i} className="fa fa-star" />);
         }
         else
         {
               rat.push(<i className="fa fa-star-o" />);
         }
         i += 1;
   }
   return rat;
}
if(props.products === null){
   return <div>null</div>
}
return (
      <Row className="products products-loop grid ciyashop-products-shortcode">
         { props.products.map((product, index) =>
              (index < 8) ?
              <Col sm={6} lg={3} key={product.id}>
                 <div className="product product_tag-black product-hover-style-default product-hover-button-style-dark product_title_type-single_line product_icon_type-line-icon">
                  <div className="product-inner element-hovered">
                     <div className="product-thumbnail fixed-295">
                        <div className="product-thumbnail-inner">
                        <Link to={`/shop/productDetail/${product.id}`}>
                              <div className="product-thumbnail-main">
                                 <img src={`${Domain}${product.pictures[0]}`} className="img-fluid" alt="shop"/>

                              </div>
                              <div className="product-thumbnail-swap">
                                 <img src={`${Domain}${product.pictures[1]}`} className="img-fluid" alt="shop"/>

                              </div>
                           </Link>
                        </div>
                        <div className="product-actions">
                           <div className="product-actions-inner">
                              <div className="product-action product-action-add-to-cart">
                                 {!CheckCardItem(product.id) ?
                                       <Link onClick={()=>AddToCart(product.id,product.name,product.pictures[0],1,product.salePrice,product.shippingCost,"In Stock")}  className="button add_to_cart_button" rel="nofollow" to= '#'>Add to
                                        cart</Link>
                                 :
                                       <Link  to="/ShopingCart"  className="button add_to_cart_button" rel="nofollow">View Cart</Link>
                                 }

                              </div>
                              <div className="product-action product-action-wishlist">
                                 {!CheckWishList(product.id) ?
                                    <Link onClick={()=>AddToWishList(product.id,product.name,product.pictures[0],1,product.salePrice,"In Stock")} className="add_to_wishlist" data-toggle="tooltip" data-original-title="Wishlist" data-placement="top" to= '#'> Add to
                                 Wishlist</Link>
                                 :
                                       <Link to="/wishlist" className="add_to_wishlist_fill" data-toggle="tooltip" data-original-title="Wishlist" data-placement="top">View Wishlist</Link>
                                 }
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="product-info">
                        <span className="ciyashop-product-category">
                           {product.category}
                        </span>
                        <h3 className="product-name">
                           <Link to={`/shop/productDetail/${product.id}`}>{product.name} </Link>
                        </h3>
                        <div className="product-rating-price">
                        <span className="price">
                           <ins>
                              <span className="price-amount amount">
                                 <span className="currency-symbol">$</span>{product.salePrice}
                              </span>
                              <span className="price-amount" style= {{marginLeft: '5px', textDecoration: 'line-through'}}>
                                 <span className="currency-symbol">$</span>{product.price}
                              </span>
                           </ins>
                        </span>
                        <div className="product-rating">{rating(product.rating)}</div>
                        </div>
                        <div className="product-details__short-description">
                           <p>
                 </p>
                        </div>
                     </div>
                  </div>
               </div>
               </Col>
              :
              <div></div>
            )}

         </Row>
      )
}


export default TopSellingProduct
